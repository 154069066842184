.avatar-member {
  border: solid;
  border-color: #444;
  border-width: 2px;
}

.avatar-img {
  :hover {
    zoom: 2;
  }
}