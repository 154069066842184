@import "_vars";

body {
    background-color: #E5F2F4;;
}

.container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.container-1200 {
    max-width: 960px;
    margin: auto;
    background-color: #E5F2F4;
    @media (max-width: 1200px) {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}

.dash-layout-inner {
    margin-left: 192px;
    min-height:100%;
    background: #fff;
    @media (max-width: @sidebar-breakpoint) {
        margin-left: 82px;
    }
}

.dash-container-1200 {
    max-width: 960px;
    margin: auto;
    @media (max-width: @sidebar-breakpoint) {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}

.dash-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
}