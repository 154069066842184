@import "_vars";

.list-item {
  //padding: 16px;
  //margin: 16px;
  :hover {
    background-color: @colors[list-item-hover];
  }
}

.list-item-active {
  //padding: 16px;
  //margin: 16px;
  background-color: @colors[list-item-active];
}

.list-item-meta {
  padding: 12px;
  //margin: 16px;
}