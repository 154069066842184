
@import "_vars";

.step-container {
  background-color: #FFFFFF;
  border-radius: 20px;
  min-width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  //background-image: url('../pics/logo_back.svg');
}

.step-btn {
  display: inline-block;
  //border-radius: 4px;
  background-color: @colors[success];
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  //width: 200px;
  width: 100%;
  transition: all 0.5s;
  cursor: pointer;
  //margin: 5px;
}

.step-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.step-btn-prev {
  border-bottom-left-radius: 20px;
  background-color: #C4E0E6;
  &:hover > span {
      padding-right: 25px;
  }
}
.step-btn-next {
  border-bottom-right-radius: 20px;
  background-color: #85BFCB;
  &:hover > span {
    padding-left: 25px;
  }
}

.step-corner {
  display: inline-block;
  padding: 10px;
  color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
}

.step-corner-left {
  border-top-left-radius: 20px;
  background-color: #007992;
}
.step-corner-right {
  border-top-right-radius: 20px;
  background-color: #FC1010;
}

.video-icon {
  fill: #007992;
  :hover {
    opacity: .8;
  }
}
