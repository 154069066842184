@import '~antd/lib/style/themes/default.less';


@sidebar-breakpoint: 1200px;

@colors: {
  success: @success-color;
  danger: @btn-danger-bg;
  list-item-hover: #fbf0ff;
  list-item-active: #c187d6;
}

.test-base {
  color: @primary-color;
}



