/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.test-base {
  color: #007992;
}
body {
  background-color: #E5F2F4;
}
.container {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.container-1200 {
  max-width: 960px;
  margin: auto;
  background-color: #E5F2F4;
}
@media (max-width: 1200px) {
  .container-1200 {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}
.dash-layout-inner {
  margin-left: 192px;
  min-height: 100%;
  background: #fff;
}
@media (max-width: 1200px) {
  .dash-layout-inner {
    margin-left: 82px;
  }
}
.dash-container-1200 {
  max-width: 960px;
  margin: auto;
}
@media (max-width: 1200px) {
  .dash-container-1200 {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}
.dash-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}
.avatar-member {
  border: solid;
  border-color: #444;
  border-width: 2px;
}
.avatar-img :hover {
  zoom: 2;
}
.list-item :hover {
  background-color: #fbf0ff;
}
.list-item-active {
  background-color: #c187d6;
}
.list-item-meta {
  padding: 12px;
}
.step-container {
  background-color: #FFFFFF;
  border-radius: 20px;
  min-width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.step-btn {
  display: inline-block;
  background-color: #52c41a;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  width: 100%;
  transition: all 0.5s;
  cursor: pointer;
}
.step-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.step-btn-prev {
  border-bottom-left-radius: 20px;
  background-color: #C4E0E6;
}
.step-btn-prev:hover > span {
  padding-right: 25px;
}
.step-btn-next {
  border-bottom-right-radius: 20px;
  background-color: #85BFCB;
}
.step-btn-next:hover > span {
  padding-left: 25px;
}
.step-corner {
  display: inline-block;
  padding: 10px;
  color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.step-corner-left {
  border-top-left-radius: 20px;
  background-color: #007992;
}
.step-corner-right {
  border-top-right-radius: 20px;
  background-color: #FC1010;
}
.video-icon {
  fill: #007992;
}
.video-icon :hover {
  opacity: 0.8;
}
